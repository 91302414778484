import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import Layout from "../components/layout";
import Logo from "../images/Bivver.png";
import SEO from "../components/seo";
import api from "../api";

export default function LandingPage({ style, modalProps }) {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data, e) => {
    console.log(e, data.Email);
    await api.post("/email", {
      to: "ricardorkanopp@gmail.com",
      from: data.Email,
      message: data.Mensagem
    });
  };
  return (
    <Layout style={style} image {...modalProps}>
      <SEO keywords={[`Bivver`, `Construção`]} title="Envie um e-mail" />
      <img src={Logo} className="w-3/4 md:w-1/5 m-auto" alt="" />

      <h3 className="mt-5 text-center">Envie-nos uma mensagem!</h3>

      <div className="form mx-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full lg:w-7/12 mx-auto"
        >
          <input
            name="Nome"
            ref={register}
            placeholder="Nome"
            className="my-5"
          />
          <input
            name="Email"
            ref={register}
            placeholder="E-mail"
            type="email"
            className="my-5"
          />
          <input
            name="Telefone"
            ref={register}
            placeholder="Telefone"
            className="my-5"
          />
          <textarea
            type="textarea"
            name="Mensagem"
            ref={register}
            rows={5}
            placeholder="Mensagem"
          />
          <Button className="w-full my-5" type="submit">
            Enviar
          </Button>
        </form>
      </div>
    </Layout>
  );
}

LandingPage.propTypes = {
  className: PropTypes.string,
  modalProps: PropTypes.any,
  style: PropTypes.object
};
